<template>
  <div id="Vehicles">
    <b-col class="mt-2">
      <b-form-group
        :label="'Vehicle ' + (index + 1)"
        :label-for="'vehicle' + index"
      >
        <div
          :id="'vehicle' + index"
          class="white-bg-container"
        >
          <b-row class="mb-1">
            <b-col>
              <b-form-group
                label="Manufacturer"
                label-for="manufacture"
              >
                <b-form-input
                  :id="'manufacture' + index"
                  v-model="Manufacture"
                  placeholder=""
                  name="manufacture"
                  :readonly="isReadonly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Model"
                label-for="model"
              >
                <b-form-input
                  :id="'model' + index"
                  v-model="Model"
                  placeholder=""
                  name="model"
                  :readonly="isReadonly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Year"
                label-for="year"
              >
                <v-select
                  :id="'year' + index"
                  v-model="Year"
                  label="title"
                  :options="YearList"
                  placeholder=""
                  name="year"
                  :disabled="isReadonly"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group
                label="Hybrid"
                label-for="hybrid"
              >
                <b-form-checkbox
                  :id="'hybrid' + index"
                  v-model="IsHybrid"
                  name="hybrid"
                  :disabled="isReadonly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="3">
              <b-form-group
                label="Color"
                label-for="color"
              >
                <b-form-input
                  :id="'color' + index"
                  v-model="Color"
                  placeholder=""
                  name="color"
                  :readonly="isReadonly"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Fuel Type"
                label-for="fuelType"
              >
                <v-select
                  :id="'fuelType' + index"
                  v-model="FuelType"
                  label="title"
                  :options="FuelTypeList"
                  placeholder=""
                  name="fuelType"
                  :disabled="isReadonly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Insurance"
                label-for="insurance"
              >
                <b-form-input
                  :id="'insurance' + index"
                  v-model="Insurance"
                  placeholder=""
                  name="insurance"
                  :readonly="isReadonly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-if="!isReadonly"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-danger"
              @click="deleteVehicle()"
            >
              Delete
            </b-button>
          </div>
        </div>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      CurrentVehicle: {},
      Manufacture: "",
      Model: "",
      Year: "",
      IsHybrid: false,
      Color: "",
      FuelType: "",
      Insurance: "",
      ID: null,

      YearList: [],
      FuelTypeList: ["Regular", "Premium", "Diesel", "Electric"]
    }
  },
  computed: {
    ...mapGetters({
      getUserToEdit: "users/getUserToEdit",
    }),
  },
  watch: {
    getUserToEdit: {
      handler(val) {
        this.fillVehicle(val);
      },
      deep: true,
    },
    Manufacture(val) {this.CurrentVehicle = {...this.CurrentVehicle, Manufacture: val}},
    Model(val) {this.CurrentVehicle = {...this.CurrentVehicle, Model: val}},
    Year(val) {this.CurrentVehicle = {...this.CurrentVehicle, Year: Number(val)}},
    IsHybrid(val) {this.CurrentVehicle = {...this.CurrentVehicle, IsHybrid: val}},
    Color(val) {this.CurrentVehicle = {...this.CurrentVehicle, Color: val}},
    FuelType(val) {this.CurrentVehicle = {...this.CurrentVehicle, FuelType: val}},
    Insurance(val) {this.CurrentVehicle = {...this.CurrentVehicle, Insurance: val}},
    ID(val) {this.CurrentVehicle = {...this.CurrentVehicle, ID: val}},

    CurrentVehicle(val) {
      this.$emit("updateVehicle", val, this.index);
    }
  },
  async created() {
    try {
      this.fillYear();
      this.fillVehicle(this.getUserToEdit);
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    deleteVehicle() {
      this.$emit("deleteVehicle", this.index);
    },

    fillYear() {
      this.YearList = [];
      const currentYear = new Date().getFullYear()
      for (let y = currentYear + 1; y > currentYear - 50; y--) {
        this.YearList.push(String(y))
      }
    },

    fillVehicle(val) {
      if (!val) return;
      const item = val.UserOwnVehicles[this.index]
      this.Manufacture = item.Manufacture;
      this.Model = item.Model;
      this.Year = item.Year;
      this.IsHybrid = item.IsHybrid;
      this.Color = item.Color;
      this.FuelType = item.FuelType;
      this.Insurance = item.Insurance;
      this.ID = item.ID;
    }
  }
}
</script>

<style scoped>
#Vehicles {
  width: 100%;
}
</style>