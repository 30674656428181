<template>
  <validation-observer ref="simpleRules">
    <h1 v-if="userObject.UserAccountID">
      Edit User
    </h1>
    <h1 v-if="!userObject.UserAccountID">
      Add New User
    </h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <b-form class="white-bg-container">
      <b-row>
        <!-- top center -->
        <b-col
          md="8"
          class="pr-5"
        >
          <b-row class="pb-2 pt-2">
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="First Name"
                  label-for="firstname"
                  class="required-data"
                >
                  <b-form-input
                    id="firstname"
                    v-model="userObject.FirstName"
                    placeholder=""
                    name="firstname"
                    :state="errors.length > 0 ? false:null"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group
                label="Middle Name"
                label-for="middlename"
              >
                <b-form-input
                  id="middlename"
                  v-model="userObject.MiddleName"
                  placeholder=""
                  name="middlename"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  label="Last Name"
                  label-for="lastname"
                  class="required-data"
                >
                  <b-form-input
                    id="lastname"
                    v-model="userObject.LastName"
                    placeholder=""
                    name="lastname"
                    :state="errors.length > 0 ? false:null"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="titel"
                  v-model="userObject.Title"
                  name="title"
                  placeholder=""
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Department"
                label-for="department"
              >
                <b-form-input
                  id="department"
                  v-model="userObject.Department"
                  placeholder=""
                  name="department"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  id="status"
                  v-model="userObject.Status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option"
                  :disabled="getCurrentScopes.indexOf('st2.manageusers') === -1"
                  placeholder=""
                  name="status"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-checkbox
                id="isFullTime"
                v-model="IsFullTime"
                name="isFullTime"
              >
                Full Time
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                id="isPartTime"
                v-model="IsPartTime"
                name="isPartTime"
              >
                Part Time
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                id="isSIU"
                v-model="userObject.IsSIU"
                name="isSIU"
              >
                SIU
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                id="isNYSIF"
                v-model="userObject.isNYSIF"
                name="isNYSIF"
              >
                NYSIF
              </b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <b-row class="pb-2 pt-2">
            <b-col md="4">
              <b-form-group
                label="Employee ID"
                label-for="employeeCode"
              >
                <b-form-input
                  id="employeeCode"
                  v-model="userObject.EmployeeCode"
                  placeholder=""
                  name="employeeCode"
                  @keypress.enter.prevent
                  :formatter="formatEmployeeCode"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                  class="required-data"
                >
                  <b-form-input
                    id="email"
                    v-model="userObject.EMail"
                    placeholder=""
                    name="email"
                    :state="errors.length > 0 ? false:null"
                    :disabled="IsEditingExistingUser && !!SelectedAuthProvider"
                    @keypress.enter.prevent
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group
                label="Street Address"
                label-for="streetAddress"
              >
                <b-form-input
                  id="streetAddress"
                  v-model="userObject.StreetAddress"
                  placeholder=""
                  name="streetAddress"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="Phone"
                label-for="Phone"
              >
                <b-form-input
                  id="Phone"
                  v-model="userObject.Phone"
                  v-mask="'(###) ###-####'"
                  type="tel"
                  placeholder=""
                  name="phone"
                  @keypress.enter.prevent
                />
              </b-form-group>

            </b-col>
            <b-col>
              <b-form-group
                label="Ext"
                label-for="PhoneExt"
              >
                <b-form-input
                  id="PhoneExt"
                  v-model="userObject.PhoneExt"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="PhoneExt"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Mobile/SMS"
                label-for="MobileSms"
              >
                <b-form-input
                  id="MobileSms"
                  v-model="userObject.MobileSms"
                  placeholder=""
                  name="MobileSms"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-5 pt-2">
            <b-col>
              <b-form-group
                label="City"
                label-for="City"
              >
                <b-form-input
                  id="City"
                  v-model="userObject.City"
                  type="text"
                  placeholder=""
                  name="City"
                  @keypress.enter.prevent
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="State"
                label-for="State"
              >
                <v-select
                  id="State"
                  v-model="userObject.State"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="StatesList"
                  placeholder=""
                  name="State"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Zip Code"
                label-for="postalcode"
              >
                <ZipInput
                  id="postalcode"
                  v-model="userObject.PostalCode"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- button -->
        </b-col>
        <!-- second half of form  -->
        <b-col
          style="  border-left: 2px solid #ebe9f1;
  height: 600px;"
        >
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="Authentication Provider"
                label-for="authProvider"
              >
                <div style="padding-bottom: 20px;">
                  <v-select
                    id="authProviderId"
                    v-model="SelectedAuthProvider"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="authProviders"
                    placeholder="Auth Provider"
                    @input="onAuthProviderChanged"
                    :disabled="getCurrentScopes.indexOf('st2.manageusers') === -1"
                  />
                </div>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="User Type"
                rules="required"
              >
                <b-form-group
                  label="User Type"
                  label-for="usertype"
                >
                  <div v-if="userTypeChangeDisabled">
                    <strong>{{ userObject.UserType }}</strong>
                  </div>
                  <div v-if="!userTypeChangeDisabled">
                    <v-select
                      id="usertype"
                      v-model="SelectedUserType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="userTypes"
                      :class="{ disabled: userTypeChangeDisabled }"
                      :disabled="getCurrentScopes.indexOf('st2.manageusers') === -1"
                      placeholder="User Type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>

                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="User Role"
                label-for="Roles"
              >
                <v-select
                  id="Roles"
                  v-model="SelectedRoles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="userRolesFilteredByUserType"
                  multiple
                  :disabled="getCurrentScopes.indexOf('st2.manageusers') === -1"
                  placeholder="User Roles"
                  name="Roles"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pb-2 pt-2">
            <b-col>
              <b-form-group
                label="Special Permissions"
                label-for="SpecialPermissions"
              >
                <v-select
                  id="SpecialPermissions"
                  v-model="SelectedAdHocScopes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="SpecialPermissions"
                  multiple
                  :disabled="getCurrentScopes.indexOf('st2.manageusers') === -1"
                  placeholder="Special Permissions"
                  name="AdHocScopes"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row class="pb-2 pt-2">
        <b-col>
          <b-row>
            <VehicleInfo
              v-for="(vehicle, index) of userObject.UserOwnVehicles"
              :key="vehicle.ID"
              :vehicle="vehicle"
              :index="index"
              @updateVehicle="updateVehicle"
              @deleteVehicle="deleteVehicle"
            />
          </b-row>
          <b-button
            type="button"
            variant="primary"
            class="mb-1"
            style="margin-top: 1rem"
            @click="addVehicle()"
          >
            <span>+ Add Vehicle</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="validationForm()"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="resetForm()"
          >
            Cancel
          </b-button>
        </b-col>
        <!-- submit and reset -->
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BBreadcrumb, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import APIService from "@core/utils/APIService";

const apiService = new APIService();

import vSelect from 'vue-select'
import { required, email } from '@validations'
import ZipInput from "@core/components/zipInput/ZipInput.vue";
import VehicleInfo from "@/views/admin/components/VehicleInfo";
import {mapGetters, mapMutations} from "vuex";
import {getSpecialID} from "@core/utils/offlineService";

export default {
  components: {
    VehicleInfo,
    ZipInput,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BBreadcrumb,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isHR: false,
      userObject: {
        UserOwnVehicles: []
      },
      IsFullTime: false,
      IsPartTime: false,
      SelectedRoles: [],
      SelectedAdHocScopes: [],
      SpecialPermissions: [],
      SelectedAuthProvider: null,
      SelectedUserType: { title: 'User' },
      AllRoles: [],
      IsEditingExistingUser: false,
      userRoles: [],
      userTypes: [],
      authProviders: [],
      userTypeChangeDisabled: false,
      option: [{ title: 'Active', value: 'Active' }, { title: 'Locked', value: 'Locked' }, { title: 'Inactive', value: 'Inactive' }],
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      required,
      email,
    }
  },
  watch: {
    IsPartTime(val) {
      if (val) {
        this.IsFullTime = false;
        this.userObject.IsFullTime = false;
        this.userObject.IsPartTime = true;
      }
    },
    IsFullTime(val) {
      if (val) {
        this.IsPartTime = false;
        this.userObject.IsPartTime = false;
        this.userObject.IsFullTime = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),

    userRolesFilteredByUserType: function() {
      var selectedUserType = this.SelectedUserType.title;
      var roles = this.AllRoles.filter(x => x.EligibleUserTypes.includes(selectedUserType)).map(data => ({ title: data.Name, value: data.ID }))
      return roles;
    },
    breadcrumbItems: function() {
      if (this.isHR)
          return [
            {
              text: 'HR',
              href: '#'
            },
            {
              text: 'Users',
              href: '/hr/users'
            },
            {
              text: 'Edit User'
            }
          ]
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Users & Permissions',
          href: '/admin/users-and-permissions'
        },
        {
          text: 'Users',
          href: '/admin/users-and-permissions/users'
        },
        {
          text: this.userObject.UserAccountID ? 'Edit User' : 'Add New User'
        }
      ]
    }
  },
  async created() {
    try {
      const response = await apiService.get('users/types').then(res => res.data).catch(error => error)
      const filterTypes = response.filter(x => x.UserType !== 'Client').map(data => ({ title: data.UserType }))
      this.userTypes = filterTypes
    } catch (err) {
      this.error = err
    }

    try {
      const response = await apiService.get('permissions/roles').then(res => res.data);
      this.AllRoles = response;
      const roles = response.map(data => ({ title: data.Name, value: data.ID }))
      const obj = Object.fromEntries(
        roles.map(count => [count, {
          title: count,
        }]),
      )
      this.userRoles = roles
    } catch (err) {
      this.error = err
    }

    try {
      const response = await apiService.get('permissions/scopes/adhoc').then(res => res.data)
      const specialPerm = response.map(data => ({ title: data.Title, value: data.Scope }))
      this.SpecialPermissions = specialPerm
    } catch (err) {
      this.error = err
    }

    try {
      const response = await apiService.get('auth/providers').then(res => res.data)
      const providers = response.map(data => ({ title: data.Name, value: data.ID }));
      var noProviderValue = { title: '(no auth provider)', value: '' };
      providers.unshift(noProviderValue);
      this.authProviders = providers;
      this.setCurrentAuthProvider();
    } catch (err) {
      this.error = err
    }
  },
  mounted() {
    //console.log('User ' + this.$route.params.userId);
    var existingUserId = this.$route.params.userId;
    this.isHR = this.$route.meta.forHR;
    if (existingUserId) {
      apiService
          .get("user/" + existingUserId + "/detail")
          .then((response) => {
            this.userObject = response.data;
            this.IsPartTime = response.data.IsPartTime;
            this.IsFullTime = response.data.IsFullTime;
            this.SelectedRoles = response.data.Roles.map(data => ({ title: data.Name, value: data.ID }));
            this.SelectedAdHocScopes = response.data.AdHocScopes.map(data => ({ title: data.Title, value: data.Scope }));
            this.SelectedUserType = { title: response.data.UserType };
            this.userTypeChangeDisabled = true;
            this.IsEditingExistingUser = true;
            this.setUserToEdit(this.userObject)
            this.setCurrentAuthProvider();
          });
    } else {
      this.userObject.Status = { title: 'Active', value: 'Active' };
    }
  },
  // our methods
  methods: {
    ...mapMutations({
      setUserToEdit: "users/setUserToEdit",
    }),

    setCurrentAuthProvider() {
      if (this.userObject === null || this.userObject === undefined) { return; }
      if (this.authProviders === null || this.authProviders.length === 0) { return; }
      if (this.userObject.AuthProviderID === null) { return; }
      var currentProvider = this.authProviders.filter(obj => {
        return obj.value === this.userObject.AuthProviderID
      });
      this.SelectedAuthProvider = currentProvider;
    },

    onAuthProviderChanged(value) {
      this.userObject.AuthProviderID = value ? value.value : null;
    },

    showToast(variant, position, timeout, data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'InfoIcon',
          text: data,
          variant,
        },
      },
      {
        position,
        timeout,
      })
    },

    resetForm() {
      this.userObject = {};
      this.SelectedRoles = [];
      this.SelectedAdHocScopes = [];
      this.$router.push('/admin/users-and-permissions/users');
    },

    processForm() {
      var postData = this.userObject;
      postData.Status = this.userObject.Status ? typeof this.userObject.Status === 'string' ? this.userObject.Status : this.userObject.Status.value : null;

      postData.AdHocScopes = this.SelectedAdHocScopes.map(x => x.value);
      postData.Roles = this.SelectedRoles.map(x => x.value);
      postData.UserType = this.SelectedUserType.title;

      var endpoint = 'user/create';
      if (postData.UserAccountID)
      {
        endpoint = 'user/' + postData.UserAccountID;
      }

      if (postData.UserOwnVehicles) {
        postData.UserOwnVehicles.forEach(i => {
          if (typeof i.ID === "string") {
            delete i.ID;
          }
        });
      }

      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'User saved!');
          if (res.data.success) {
            this.$router.push('/admin/users-and-permissions/users');
          } else {
            this.showToast('warning', 'top-center', 5000, res.data.message);
          }
        }
      })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            this.processForm()
        }
      })
    },

    addVehicle() {
      if (!this.userObject.UserOwnVehicles) this.userObject.UserOwnVehicles = [];
      const item = {ID: getSpecialID()}
      this.userObject.UserOwnVehicles.push(item)
      this.setUserToEdit(this.userObject)
    },

    updateVehicle(val, index) {
      let item = {
        Manufacture: "",
        Model: "",
        Year: "",
        Color: "",
        FuelType: "",
        Insurance: "",
        IsHybrid: false,
        ID: this.userObject.UserOwnVehicles[index].ID,
      }
      item = {...item, val}
      this.userObject.UserOwnVehicles.splice(index, 1, val);
    },

    deleteVehicle(index) {
      this.userObject.UserOwnVehicles.splice(index, 1);
      this.setUserToEdit(this.userObject)
    },

    formatEmployeeCode(e) {
      return String(e).substring(0, 9);
    }
  },
}
</script>

<style>
   /* neeed to style placehoder in vselect  */
   hr{
     border: 1px solid #ebe9f1;
   }
</style>
